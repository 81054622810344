import React, {createContext, useEffect, useState, useContext} from 'react'

const defaultValues = {
  planData: {},
  userData: {}

}
export const CheckoutContext = createContext(defaultValues)


export const CheckoutProvider = ({children}) => {
  const [planData, setPlanData] = useState(defaultValues.planData)
  const [userData, setUserData] = useState({})
  const [paymentData, setPaymentData] = useState({})


  const setPlanD = async (object) => {
    try{

      await setPlanData(object)
      console.log(planData)
    }
    catch (e) {
      console.log(e)
    }
  }
  
  return (
    <CheckoutContext.Provider value={{
  
      planData,
      setPlanD
      
    }}
      
    >
      {children}
    </CheckoutContext.Provider>
  )
}
